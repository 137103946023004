import React, { ReactElement, useEffect, useState } from 'react';

import { Router } from '@reach/router'
import { graphql, navigate } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import { IPageProps, IPageData, IBlock } from '@Types/ContentfulTypes';
import AdvanceInquiryFundingPage from '@Src/templates/advance/inquiry/funding';
import AdvanceInquiryStatementsPage from '@Src/templates/advance/inquiry/statements';
import AdvanceInquirySubmittedPage from '@Src/templates/advance/inquiry/submitted';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeCampaign: IPageData;
	};
}

const DashboardPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
  // @ts-ignore
	const { setTheme: setPageTheme } = usePageTheme();
	const { pageType, setPageType, setPageTitle } = useApp();
	const { mode } = useApp();
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  console.log(localTransStatus)
	useEffect(() => {
		setPageType('signup');
	}, []);

	useEffect(() => {
    if (pageType === 'index' && transitionStatus == 'exiting') {
        setLocalTransStatus(transitionStatus + 'Index');
    } else {
        setLocalTransStatus(transitionStatus);
    }
		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
			setPageTitle('index');
			setPageType('dashboard');
		}
	}, [transitionStatus]);

	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme(mode == 'day' ? 'yellow' : 'dark');
      if (location.pathname === '/dashboard') {
        navigate('/dashboard/overview')
      }
		}
	}, [mode]); 

	return ( 
    <ContentLayout transitionState={localTransStatus}>
      <Router style={{padding: 0}} basepath="/dashboard">
        {/* @ts-ignore */}
        <AdvanceInquiryFundingPage transitionStatus={localTransStatus} path="advance/inquiry/:advanceInquiryId/funding" />
        {/* @ts-ignore */}
        <AdvanceInquiryStatementsPage transitionStatus={localTransStatus} path="advance/inquiry/:advanceInquiryId/statements" />
        {/* @ts-ignore */}
        <AdvanceInquirySubmittedPage transitionStatus={localTransStatus} path="advance/inquiry/:advanceInquiryId/submitted" />
  
    </Router>
   </ContentLayout>
	);
};

export default DashboardPage; 
